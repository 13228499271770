import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { getTokenOrRefresh } from './../token_util';
import './../custom.css';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import MessageList from './../MessageList';
import { saveToFile, saveToFileShort } from './../saveToFile';
import { Link } from "react-router-dom";

const speechsdk = require('microsoft-cognitiveservices-speech-sdk')

class Classroom extends Component {

   constructor(props) {
      super(props);
      this.state = {
         displayText: 'INITIALIZED: ready to test speech...',
         translatedText: '',
         messages: [],
         displayTranslation: false,
         startMessages: 'START',
         recognizer: '',
         shortDestinationLanguage: '',
         destinationLanguage: '',
         originLanguage: '',
         className: '',
         classroom: '',
         fileName: '',
         txtContent: '',
         txtContentOriginal: '',
         classesOK: ['d1', 'd2', 'd3', 'k4', 'aa', 't1', 't2', 'ch1', 'ch2', 'cib', 'apollo', 'mercury'],
         testing: false,
         resolution: 'big',
      }
      this.ModifyStartButtonText = this.ModifyStartButtonText.bind(this);
      const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false })
      permissions.then((stream) => {
      })
         .catch((err) => {
            console.log(`${err.name} : ${err.message}`)
         });
   }

   async ModifyStartButtonText() {
      setInterval(() => {
         if (this.state.startMessages === 'START') {
            this.setState({ startMessages: 'EMPEZAR' });
         } else {
            this.setState({ startMessages: 'START' });
         }
      }, 1000);
   }

   async componentDidMount() {
      const tokenRes = await getTokenOrRefresh();
      if (tokenRes.authToken === null) {
         this.setState({
            displayText: 'FATAL_ERROR: ' + tokenRes.error,
            translatedText: 'Fatal Error'
         });
      }
   }

   async sttFromMic(originLanguage, shortDestinationLanguage, destinationLanguage, classname = null) {

      if (this.state.className === '') {
         if (classname !== null && classname !== 'undefined') {
            this.setState({
               className: classname,
            });
         } else {
            classname = this.state.className;
         }
      } else {
         classname = this.state.className;
      }

      console.log('Welcome to WATSON V4 - Class ' + classname);

      const date = new Date();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hour = ("0" + (date.getHours() + 1)).slice(-2);
      const filename = classname + '-contentfile__' + date.getFullYear() + '-' + month + '-' + day + '-' + hour;

      this.setState({
         shortDestinationLanguage: shortDestinationLanguage,
         destinationLanguage: destinationLanguage,
         originLanguage: originLanguage,
         fileName: filename,
      });

      if (classname === 'd1' || classname === 'd2' || classname === 'd3') {
         this.setState({
            resolution: 'big',
         });
      }

      if (classname === 't1' || classname === 't2' || classname === 'aa' || classname === 'k4' || classname === 'ch1' || classname === 'ch2') {
         this.setState({
            resolution: 'small',
         });
      }

      if (classname === 'cib') {
         this.setState({
            resolution: 'cib',
         });
      }

      let tokenObj = await getTokenOrRefresh();

      let speechConfig = speechsdk.SpeechTranslationConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
      speechConfig.speechRecognitionLanguage = this.state.originLanguage;
      speechConfig.addTargetLanguage(this.state.destinationLanguage);
      let audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

      let recognizer = new speechsdk.TranslationRecognizer(speechConfig, audioConfig);

      this.setState({
         displayText: 'speak into your microphone...',
         displayTranslation: true,
         recognizer: recognizer
      });
      recognizer.recognizing = (s, e) => {
         let formattedTranslation = e.result.translations.get(this.state.shortDestinationLanguage);
         if (this.state.resolution === 'small') {
            if (formattedTranslation.length > 200) {
               formattedTranslation = formattedTranslation.substring(formattedTranslation.length - 200)
            }
         }
         if (this.state.resolution === 'big') {

            if (formattedTranslation.length > 240) {

               formattedTranslation = formattedTranslation.substring(formattedTranslation.length - 240)
            }
         }
         if (this.state.resolution === 'cib') {

            if (formattedTranslation.length > 160) {

               formattedTranslation = formattedTranslation.substring(formattedTranslation.length - 160)
            }

         }

         this.setState({
            displayText: formattedTranslation
         });
      };
      recognizer.recognized = (s, e) => {

         if (e.result.reason === ResultReason.TranslatedSpeech) {

            console.log('Text lenght - ' + this.state.txtContent.length);
            const message = { 'key': new Date(), 'message': e.result.translations.get(this.state.shortDestinationLanguage), 'time': new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) };
            let txtContentTranslated = this.state.txtContent + '\n' + e.result.translations.get(this.state.shortDestinationLanguage);
            let txtContentOriginal = this.state.txtContentOriginal + '\n' + e.result.text;
            this.setState({
               txtContent: txtContentTranslated,
               txtContentOriginal: txtContentOriginal
            });

            if (this.state.txtContent.length > 400) {
               saveToFile(this.state.fileName, this.state.txtContent, this.state.destinationLanguage)
                  .then((value) => { console.log('Success saving file'); this.setState({ txtContent: '' }); })
                  .catch((error) => { console.log('Error saving file -- ' + error); });
            }

            if (this.state.txtContentOriginal.length > 400) {
               saveToFile(this.state.fileName, this.state.txtContentOriginal, this.state.originLanguage)
                  .then((value) => { console.log('Success saving file'); this.setState({ txtContentOriginal: '' }); })
                  .catch((error) => { console.log('Error saving file -- ' + error); });
            }

            this.setState((state) => {
               return {
                  translatedText: '',
                  messages: [...state.messages, message]
               }
            });
         }
         if (e.result.reason === ResultReason.RecognizedSpeech) {
            console.log(`Recognized Speech: Text=${e.result.text}`);

            const message = { 'key': new Date(), 'message': e.result.text, 'time': new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) };
            let txtContentTranslated = this.state.txtContent + '\n' + e.result.text;
            let txtContentOriginal = this.state.txtContentOriginal + '\n' + e.result.text;
            this.setState({
               txtContent: txtContentTranslated,
               txtContentOriginal: txtContentOriginal
            });

            this.setState((state) => {
               return {
                  translatedText: '',
                  messages: [...state.messages, message]
               }
            });
         }
         else if (e.result.reason === ResultReason.NoMatch) {
            console.log("NOMATCH: Speech could not be translated.");
         }
      };
      recognizer.canceled = (s, e) => {
         console.log(`CANCELED: Reason=${e.reason}`);
         recognizer.close();
         recognizer = undefined;
         this.sttFromMic(
            this.state.originLanguage
            , this.state.shortDestinationLanguage
            , this.state.destinationLanguage
            , this.state.className
         );
      };
      recognizer.sessionStopped = (s, e) => {
         console.log("\n    Session stopped event.");
      };

      await recognizer.startContinuousRecognitionAsync(
         function (result) {
            console.log('Result startContinuousRecognitionAsync -- ' + result);
         }
         ,
         function (err) {
            recognizer.close();
            recognizer = undefined;

            this.sttFromMic(
               this.state.originLanguage
               , this.state.shortDestinationLanguage
               , this.state.destinationLanguage
               , this.state.className
            );
         });
   }

   async stopTranslation() {

      this.state.recognizer.stopContinuousRecognitionAsync();
      this.state.recognizer.close();
      this.setState({ recognizer: undefined });

      //function save to files
      saveToFileShort(this.state.fileName, this.state.txtContent, this.state.destinationLanguage)
         .then((value) => { console.log('Success saving file'); this.setState({ txtContent: '' }); })
         .catch((error) => { console.log('Error saving file -- ' + error); });

      saveToFileShort(this.state.fileName, this.state.txtContentOriginal, this.state.originLanguage)
         .then((value) => { console.log('Success saving file'); this.setState({ txtContentOriginal: '' }); })
         .catch((error) => { console.log('Error saving file -- ' + error); });

      this.setState({ displayTranslation: false, messages: [] });

   }

   async changeResolution(res) {
      this.setState({
         resolution: res,
      });
   }

   async fileChange(event) {
      const audioFile = event.target.files[0];
      console.log(audioFile);
      const fileInfo = audioFile.name + ` size=${audioFile.size} bytes `;

      this.setState({
         displayText: fileInfo
      });

      const tokenObj = await getTokenOrRefresh();
      const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
      speechConfig.speechRecognitionLanguage = 'es-ES';

      const audioConfig = speechsdk.AudioConfig.fromWavFileInput(audioFile);
      const recognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

      recognizer.recognizeOnceAsync(result => {
         let displayText;
         if (result.reason === ResultReason.RecognizedSpeech) {
            displayText = `RECOGNIZED: Text=${result.text}`
         } else {
            displayText = 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.';
         }

         this.setState({
            displayText: fileInfo + displayText
         });
      });
   }

   async hardRefresh() {
      await this.stopTranslation();
      //window.location.href = window.location.href;
      window.location.reload();

   }

   async refreshAt(hours, minutes, seconds) {
      let now = new Date();
      let then = new Date();

      if (now.getHours() > hours ||
         (now.getHours() === hours && now.getMinutes() > minutes) ||
         now.getHours() === hours && now.getMinutes() === minutes && now.getSeconds() >= seconds) {
         then.setDate(now.getDate() + 1);
      }
      then.setHours(hours);
      then.setMinutes(minutes);
      then.setSeconds(seconds);

      let timeout = (then.getTime() - now.getTime());
      setTimeout(function () {
         saveToFileShort(this.state.fileName, this.state.txtContent, this.state.destinationLanguage);
         saveToFileShort(this.state.fileName, this.state.txtContentOriginal, this.state.originLanguage);
         window.location.reload(true);
      }, timeout);
   }

   render() {
      let { id } = this.props.match.params;
      if (this.state.classesOK.indexOf(id) !== -1) {
         if (id === 'apollo') {
            return (
               <Container
                  className={this.state.displayTranslation ? ('app-container') : ('app-container containerCenter')}>
                  {!this.state.displayTranslation ? (
                     <Link id="linkhome" to={'/'} onClick={this.forceUpdate}
                        className="class-menu__link"> ← </Link>
                  ) : null}
                  <div className="header__container">
                     <p className="titleText">WATS - {id.toUpperCase()}</p>
                     {this.state.displayTranslation ? (
                        <div className="header-buttons">
                           <div className="header-buttons__container">
                              <button className="refresh__button" onClick={() => this.hardRefresh()}>Refresh
                              </button>
                           </div>
                           {this.state.resolution === 'big' ? (
                              <div className="header-buttons__container">
                                 <button className="resolution__button"
                                    onClick={() => this.changeResolution('small')}>-
                                 </button>
                              </div>
                           ) : null}
                           {this.state.resolution === 'small' ? (
                              <div className="header-buttons__container">
                                 <button className="resolution__button"
                                    onClick={() => this.changeResolution('big')}>+
                                 </button>
                              </div>
                           ) : null}
                           <div className="header-buttons__container">
                              <button className="stop__button" onClick={() => this.stopTranslation()}>Terminar
                                 clase
                              </button>
                           </div>
                        </div>
                     ) : null}
                  </div>
                  {this.state.displayTranslation ? (
                     (() => {
                        return (
                           <div id="displayTranslation"
                              className={"displayTranslation__container displayTranslation__container--" + id + " displayTranslation__container--" + this.state.resolution}>
                              <div className="main-container">
                                 <div className="rounded">
                                    <MessageList data={this.state.messages} scroll={'bottom'} />
                                 </div>
                              </div>
                              <div className="main-container">
                                 <div className="toTranslateContainer rounded">
                                    <li className="messageItem">
                                       <div className="messageItemText">{this.state.displayText}</div>
                                    </li>
                                 </div>
                              </div>
                           </div>
                        )
                     })()
                  ) : (
                     <div className="button__container">
                        <div className="start__button-container">
                           <button className="start__button"
                              onClick={() => this.sttFromMic('ar-SA', 'en', 'en-US', id)}>FROM ARABIC TO
                              SPANISH
                           </button>
                           <button className="start__button"
                              onClick={() => this.sttFromMic('en-US', 'ar', 'ar-SA', id)}>FROM ENGLISH TO
                              ARABIC
                           </button>
                        </div>
                     </div>
                  )}
               </Container>
            );
         } else if (id === 'mercury') {
            return (
               <Container
                  className={this.state.displayTranslation ? ('app-container') : ('app-container containerCenter')}>
                  {!this.state.displayTranslation ? (
                     <Link id="linkhome" to={'/'} onClick={this.forceUpdate}
                        className="class-menu__link"> ← </Link>
                  ) : null}
                  <div className="header__container">
                     <p className="titleText">WATS - {id.toUpperCase()}</p>
                     {this.state.displayTranslation ? (
                        <div className="header-buttons">
                           <div className="header-buttons__container">
                              <button className="refresh__button" onClick={() => this.hardRefresh()}>Refresh
                              </button>
                           </div>
                           {this.state.resolution === 'big' ? (
                              <div className="header-buttons__container">
                                 <button className="resolution__button"
                                    onClick={() => this.changeResolution('small')}>-
                                 </button>
                              </div>
                           ) : null}
                           {this.state.resolution === 'small' ? (
                              <div className="header-buttons__container">
                                 <button className="resolution__button"
                                    onClick={() => this.changeResolution('big')}>+
                                 </button>
                              </div>
                           ) : null}
                           <div className="header-buttons__container">
                              <button className="stop__button" onClick={() => this.stopTranslation()}>Terminar
                                 clase
                              </button>
                           </div>
                        </div>
                     ) : null}
                  </div>
                  {this.state.displayTranslation ? (
                     (() => {
                        return (
                           <div id="displayTranslation"
                              className={"displayTranslation__container displayTranslation__container--" + id + " displayTranslation__container--" + this.state.resolution}>
                              <div className="main-container">
                                 <div className="rounded">
                                    <MessageList data={this.state.messages} scroll={'bottom'} />
                                 </div>
                              </div>
                              <div className="main-container">
                                 <div className="toTranslateContainer rounded">
                                    <li className="messageItem">
                                       <div className="messageItemText">{this.state.displayText}</div>
                                    </li>
                                 </div>
                              </div>
                           </div>
                        )
                     })()
                  ) : (
                     <div className="button__container">
                        <div className="start__button-container">
                           <button className="start__button"
                              onClick={() => this.sttFromMic('fr-FR', 'en', 'en-US', id)}>FROM FRENCH TO
                              ENGLISH
                           </button>
                           <button className="start__button"
                              onClick={() => this.sttFromMic('en-US', 'fr', 'fr-FR', id)}>FROM ENGLISH TO
                              FRENCH
                           </button>
                        </div>
                     </div>
                  )}
               </Container>
            );
         } else {
            return (
               <Container
                  className={this.state.displayTranslation ? ('app-container') : ('app-container containerCenter')}>
                  {!this.state.displayTranslation ? (
                     <Link id="linkhome" to={'/'} onClick={this.forceUpdate}
                        className="class-menu__link"> ← </Link>
                  ) : null}
                  <div className="header__container">
                     <p className="titleText">WATS - {id.toUpperCase()}</p>
                     {this.state.displayTranslation ? (
                        <div className="header-buttons">
                           <div className="header-buttons__container">
                              <button className="refresh__button" onClick={() => this.hardRefresh()}>Refresh
                              </button>
                           </div>
                           {this.state.resolution === 'big' ? (
                              <div className="header-buttons__container">
                                 <button className="resolution__button"
                                    onClick={() => this.changeResolution('small')}>-
                                 </button>
                              </div>
                           ) : null}
                           {this.state.resolution === 'small' ? (
                              <div className="header-buttons__container">
                                 <button className="resolution__button"
                                    onClick={() => this.changeResolution('big')}>+
                                 </button>
                              </div>
                           ) : null}
                           <div className="header-buttons__container">
                              <button className="stop__button" onClick={() => this.stopTranslation()}>Terminar
                                 clase
                              </button>
                           </div>
                        </div>
                     ) : null}
                  </div>
                  {this.state.displayTranslation ? (
                     (() => {
                        return (
                           <div id="displayTranslation"
                              className={"displayTranslation__container displayTranslation__container--" + id + " displayTranslation__container--" + this.state.resolution}>
                              <div className="main-container">
                                 <div className="rounded">
                                    <MessageList data={this.state.messages} scroll={'bottom'} />
                                 </div>
                              </div>
                              <div className="main-container">
                                 <div className="toTranslateContainer rounded">
                                    <li className="messageItem">
                                       <div className="messageItemText">{this.state.displayText}</div>
                                    </li>
                                 </div>
                              </div>
                           </div>
                        )
                     })()
                  ) : (
                     <div className="button__container">
                        <div className="start__button-container">
                           <button className="start__button"
                              onClick={() => this.sttFromMic('es-ES', 'en', 'en-US', id)}>DE ESPAÑOL A
                              INGLÉS
                           </button>
                           <button className="start__button"
                              onClick={() => this.sttFromMic('en-US', 'es', 'es-MX', id)}>FROM ENGLISH TO
                              SPANISH
                           </button>
                        </div>
                     </div>
                  )}
               </Container>
            );
         }
      } else {
         return (
            <div className="home home-forbidden">
               <p className="titleText">WATS</p>
               <p className="titleText titleText--big">FORBIDDEN ACCESS</p>
            </div>
         );
      }
   }
};

export default Classroom;
